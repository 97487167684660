import React from "react";

import "./css/Footer.css";

const Footer = () => {
  return (
    <React.Fragment>
      <div class="footer">
        <p>Copyright @2024 | Archive Room</p>
      </div>
    </React.Fragment>
  );
};
export default Footer;
