import React, { useEffect, useState } from "react";
import axios from "axios";

import moment from "moment";

import {
  Button,
  TextField,
  InputAdornment,
  Stack,
  Grid,
  IconButton,
  Modal,
  Card,
  CardContent,
  Box,
  Typography
} from "@mui/material";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";

import { Icon } from "@iconify/react";

import boxicon from "../imgs/box.png";

import Swal from "sweetalert2";

import { Paragraph, Document, Packer, HeadingLevel, TextRun, Tab } from "docx";

import { saveAs } from "file-saver";

import Pic_Locker from "../imgs/lockers.png";
import Pic_Shield from "../imgs/shield.png";

import "./css/Doc_list.css";

import * as xlsx from "xlsx";

const MAX_FILE_SIZE_MB = 5;
const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/gif"];

const Doc_storage_list = prop => {
  var url = new URL(window.location.href);

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3001/api/";
  } else {
    xURL = "https://finance.rihes.cmu.ac.th/api/";
  }

  const [GridHeight, setGridHeight] = useState(643);

  const [dgdataSource, setdgdataSource] = useState([]);
  const [Boxnum, setBoxnum] = useState(0);
  const [Boxuse, setBoxuse] = useState(0);

  const [dataBox, setdataBox] = useState([""]);
  const [dataBox2, setdataBox2] = useState([""]);

  const [Locker_list, setLocker_list] = useState([]);
  const [Locker_list2, setLocker_list2] = useState([]);
  const [Locker_list3, setLocker_list3] = useState([]);
  const [Locker_list4, setLocker_list4] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);

  const [User_Level, setUser_Level] = useState("");

  useEffect(() => {
    setUser_Level(localStorage.getItem("level"));

    setBoxnum(url.searchParams.get("box"));

    getCount();
    getData();

    // getLocker(url.searchParams.get("box"), [1, 5], Pic_Locker, Pic_Shield);
  }, []);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);

  const handleOpen = zdata => {
    // console.log(zdata);
    setdataBox(zdata);

    setSelectedFile(null);
    setOpen(true);
  };
  const handleOpen2 = zdata => {
    setdataBox2(zdata);
    setOpen2(true);
  };

  const getCount = () => {
    axios
      .post(xURL + "getData", {
        floor: prop.floor,
        room: url.searchParams.get("page")
      })
      .then(result => {
        let xcount = 0,
          Tblock = [];

        result.data.forEach((i, idx) => {
          xcount = xcount + 1;

          if (parseInt(i.doc_id.toString().substring(3) < 10)) {
            Tblock.push(parseInt(i.doc_id.toString().substring(4)));
          } else {
            Tblock.push(parseInt(i.doc_id.toString().substring(3)));
          }
        });

        setBoxuse(xcount);
        getLocker(url.searchParams.get("box"), Tblock, Pic_Locker, Pic_Shield);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const getData = boxname => {
    let xapi = "";

    if (!boxname) {
      xapi = "getData";
    } else {
      xapi = "getFindData";
    }

    axios
      .post(xURL + xapi, {
        floor: prop.floor,
        room: url.searchParams.get("page"),
        boxname: boxname
      })
      .then(result => {
        const Tb1 = [];

        let xend_date = "",
          xheight = 643,
          xfile = "";

        result.data.forEach((i, idx) => {
          if (i.end_date) {
            xend_date = moment(i.end_date).format("DD/MM/YYYY");
          } else {
            xend_date = "";
          }

          if (i.file_id) {
            xfile = "ไฟล์รูป";
          } else {
            xfile = "";
          }

          Tb1.push({
            no: parseInt(idx + 1),
            doc_id: (
              <a
                style={{ color: "#525CEB" }}
                onClick={() => {
                  handleOpen2(i);
                }}
              >
                {i.doc_id}
              </a>
            ),
            doc_name: (
              <a
                style={{ color: "#525CEB" }}
                onClick={() => {
                  handleOpen2(i);
                }}
              >
                {i.doc_name}
              </a>
            ),
            doc_detail: i.doc_detail,
            remark: i.remark,
            date_receive: moment(i.start_date).format("DD/MM/YYYY"),
            date_end: xend_date,
            worker: i.employ,
            edit_doc: (
              <IconButton size="small" color="primary" aria-label="edit device">
                <EditIcon fontSize="inherit" onClick={() => handleOpen(i)} />
              </IconButton>
            ),
            file: (
              <a
                style={{ textDecoration: "none" }}
                href={xURL + "op_imgs/" + i.file_name}
                target="_blank"
              >
                {xfile}
              </a>
            ),
            file_name: i.file_name,
            status: i.status_name
          });

          if (idx > 14) {
            xheight = xheight + 40;
          }
        });

        // console.log(Tblock);
        setdgdataSource(Tb1);
        setGridHeight(xheight);

        // getLocker(url.searchParams.get("box"), Pic_Locker);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const getLocker = (box, lock_img, imgs1, imgs2) => {
    // console.log(lock_img);
    let Tb1 = [],
      Tb2 = [],
      Tb3 = [],
      Tb4 = [];

    let xurl =
      "/Archive/adddoc?page=" +
      url.searchParams.get("page") +
      "&floor=" +
      prop.floor +
      "&c=" +
      url.searchParams.get("category") +
      "&b=" +
      url.searchParams.get("box");

    for (var i = 1; i <= box; i++) {
      let xalt = "Locker" + i;

      if (i <= 5) {
        if (lock_img.includes(i) == true) {
          Tb1.push(
            <Grid item xs={2.4}>
              <img
                style={{
                  width: "85%",
                  height: "85%"
                }}
                className="img-artboard"
                src={imgs2}
                alt={xalt}
              />

              <div className="box-text">
                {i}
              </div>
            </Grid>
          );
        } else {
          Tb1.push(
            <Grid className="grid-select" item xs={2.4}>
              <a href={xurl + "&lock=" + i} target="_self">
                <img
                  style={{
                    width: "85%",
                    height: "85%"
                  }}
                  className="img-artboard"
                  src={imgs1}
                  alt={xalt}
                />
              </a>

              <div className="box-text">
                {i}
              </div>
            </Grid>
          );
        }
      }

      if (i > 5 && i <= 10) {
        if (lock_img.includes(i) == true) {
          Tb2.push(
            <Grid item xs={2.4}>
              <img
                style={{
                  width: "85%",
                  height: "85%"
                }}
                className="img-artboard"
                src={imgs2}
                alt={xalt}
              />

              <div className="box-text">
                {i}
              </div>
            </Grid>
          );
        } else {
          Tb2.push(
            <Grid className="grid-select" item xs={2.4}>
              <a href={xurl + "&lock=" + i} target="_self">
                <img
                  style={{
                    width: "85%",
                    height: "85%"
                  }}
                  className="img-artboard"
                  src={imgs1}
                  alt={xalt}
                />
              </a>

              <div className="box-text">
                {i}
              </div>
            </Grid>
          );
        }
      }

      if (i > 10 && i <= 15) {
        if (lock_img.includes(i) == true) {
          Tb3.push(
            <Grid item xs={2.4}>
              <img
                style={{
                  width: "85%",
                  height: "85%"
                }}
                className="img-artboard"
                src={imgs2}
                alt={xalt}
              />

              <div className="box-text">
                {i}
              </div>
            </Grid>
          );
        } else {
          Tb3.push(
            <Grid className="grid-select" item xs={2.4}>
              <a href={xurl + "&lock=" + i} target="_self">
                <img
                  style={{
                    width: "85%",
                    height: "85%"
                  }}
                  className="img-artboard"
                  src={imgs1}
                  alt={xalt}
                />
              </a>

              <div className="box-text">
                {i}
              </div>
            </Grid>
          );
        }
      }

      if (i > 15 && i <= box) {
        if (lock_img.includes(i) == true) {
          Tb4.push(
            <Grid item xs={2.4}>
              <img
                style={{
                  width: "85%",
                  height: "85%"
                }}
                className="img-artboard"
                src={imgs2}
                alt={xalt}
              />

              <div className="box-text">
                {i}
              </div>
            </Grid>
          );
        } else {
          Tb4.push(
            <Grid className="grid-select" item xs={2.4}>
              <a href={xurl + "&lock=" + i} target="_self">
                <img
                  style={{
                    width: "85%",
                    height: "85%"
                  }}
                  className="img-artboard"
                  src={imgs1}
                  alt={xalt}
                />
              </a>

              <div className="box-text">
                {i}
              </div>
            </Grid>
          );
        }
      }
    }

    setLocker_list(Tb1);
    setLocker_list2(Tb2);
    setLocker_list3(Tb3);
    setLocker_list4(Tb4);
  };

  const ClickAddDoc = () => {
    // console.log(parseInt(Boxuse),parseInt(Boxnum));

    if (parseInt(Boxuse) >= parseInt(Boxnum)) {
      Swal.fire({
        title: "ไม่สามารถเพิ่มกล่องเอกสารได้!",
        text: "จำนวนกล่องเอกสารในชั้นนี้เต็มแล้ว",
        icon: "warning",
        // showConfirmButton: false,
        // timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else {
      window.open(
        "/Archive/adddoc?page=" +
          url.searchParams.get("page") +
          "&floor=" +
          prop.floor +
          "&c=" +
          url.searchParams.get("category") +
          "&b=" +
          url.searchParams.get("box"),
        "_self"
      );
    }
  };

  const handleEdit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!data.get("doc_name")) {
      Swal.fire({
        title: "แก้ไขไม่ได้!",
        text: "ยังไม่ได้คีย์ ชื่อกล่องเอกสาร",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else if (!data.get("doc_detail")) {
      Swal.fire({
        title: "แก้ไขไม่ได้!",
        text: "ยังไม่ได้คีย์ รายละเอียด",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else if (!data.get("date_end")) {
      Swal.fire({
        title: "แก้ไขไม่ได้!",
        text: "ยังไม่ได้คีย์ วันที่ครบกำหนด",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else {
      Swal.fire({
        title: "ยืนยัน แก้ไขข้อมูล",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(result => {
        if (result.isConfirmed) {
          // dataBox.file_name;

          const formData = new FormData();
          formData.append("file", selectedFile);

          axios.post(xURL + "uploadfile", formData).then(res => {
            if (dataBox.file_name) {
              if (selectedFile) {
                axios
                  .post(xURL + "delete_filedb", {
                    file_name: dataBox.file_name
                  })
                  .then(result => {})
                  .catch(function(error) {
                    // handle error
                    console.log("problem here", error);
                  });

                axios
                  .post(xURL + "delete_file", {
                    file_name: dataBox.file_name
                  })
                  .then(result => {})
                  .catch(function(error) {
                    // handle error
                    console.log("problem here", error);
                  });
              }
            }

            axios
              .post(xURL + "update_doc", {
                doc_name: data.get("doc_name"),
                doc_detail: data.get("doc_detail"),
                remark: data.get("remark"),
                edit_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                end_date: data.get("date_end"),
                workname: data.get("worker1"),
                room: url.searchParams.get("page"),
                doc_id: data.get("doc_id"),
                f_name: res.data.filename
              })
              .then(result => {
                Swal.fire({
                  title: "แก้ไขข้อมูลสำเร็จ!",
                  text: ":3",
                  icon: "success"
                }).then(() => {
                  setOpen(false);

                  getCount();
                  getData();
                });
              })
              .catch(function(error) {
                // handle error
                console.log("problem here", error);
              });
          });
        }
      });
    }
  };

  const deleteDoc = () => {
    Swal.fire({
      title: "ยืนยัน ลบข้อมูลนี้",
      text: dataBox.doc_name,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก"
    }).then(result => {
      if (result.isConfirmed) {
        if (dataBox.file_name) {
          axios
            .post(xURL + "delete_filedb", {
              file_name: dataBox.file_name
            })
            .then(result => {})
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          axios
            .post(xURL + "delete_file", {
              file_name: dataBox.file_name
            })
            .then(result => {})
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        }

        axios
          .post(xURL + "delete_doc", {
            doc_id: dataBox.doc_id
          })
          .then(result => {
            Swal.fire({
              title: "ลบข้อมูลสำเร็จ!",
              text: ":3",
              icon: "success"
            }).then(() => {
              setOpen(false);

              getCount();
              getData();
            });
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      }
    });
  };

  const destroyDoc = () => {
    console.log("problem here");
    Swal.fire({
      title: "ยืนยัน ทำลายข้อมูลนี้",
      text: dataBox2.doc_name,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก"
    }).then(result => {
      axios
        .post(xURL + "destroy_doc", {
          doc: dataBox2
        })
        .then(result => {
          Swal.fire({
            title: "ทำลายข้อมูลนี้แล้ว!",
            text: ":3",
            icon: "success"
          }).then(() => {
            setOpen2(false);

            getCount();
            getData();
          });
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    });
  };

  const Doc_receive = doc_id => {
    Swal.fire({
      title: "ยืนยัน คืนกล่องเอกสาร",
      text: dataBox2.doc_name,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก"
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .post(xURL + "doc_receceive", {
            doc_id: doc_id,
            receivedate: moment().format("YYYY-MM-DD"),
            receiveemp: dataBox2.employ
          })
          .then(result => {
            Swal.fire({
              title: "คืนกล่องเอกสารสำเร็จ!",
              text: ":3",
              icon: "success"
            }).then(() => {
              window.location.reload();
            });
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      }
    });
  };

  const dgcolumns = [
    {
      name: "no",
      header: "ที่",
      maxWidth: 70,
      minWidth: 50,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "doc_id",
      header: "รหัสกล่อง",
      maxWidth: 130,
      minWidth: 100,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "doc_name",
      header: "ชื่อกล่องเอกสาร",
      maxWidth: 300,
      minWidth: 120,
      // defaultFlex: 1,
      headerAlign: "center",
      //   textAlign: "center",
      sortable: false
    },
    {
      name: "doc_detail",
      header: "รายละเอียด",
      maxWidth: 450,
      minWidth: 120,
      defaultFlex: 1,
      headerAlign: "center",
      //   textAlign: "center",
      sortable: false
    },
    {
      name: "remark",
      header: "หมายเหตุ",
      maxWidth: 300,
      minWidth: 100,
      defaultFlex: 1,
      headerAlign: "center",
      sortable: false
    },
    {
      name: "worker",
      header: "ผู้ปฏิบัติงาน",
      maxWidth: 220,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "date_receive",
      header: "วันที่เพิ่มกล่อง",
      maxWidth: 170,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "date_end",
      header: "วันที่ครบกำหนด",
      maxWidth: 170,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "edit_doc",
      header: "แก้ไข",
      maxWidth: 90,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "file",
      header: "ไฟล์",
      maxWidth: 90,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "status",
      header: "สถานะ",
      maxWidth: 90,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false,
      onRender: (cellProps, { data }) => {
        cellProps.style.background = data.status == "ยืม" ? "red" : "";
        cellProps.style.color = data.status == "ยืม" ? "#fff" : "";
      }
    }
  ];

  const gridStyle = { minHeight: GridHeight, marginTop: "10px" };

  const scrollProps = Object.assign(
    {},
    ReactDataGrid.defaultProps.scrollProps,
    {
      autoHide: false
    }
  );

  const searchname = values => {
    // console.log(values);
    getData(values);
  };

  const handleFileChange = event => {
    const file = event.target.files[0];

    // File type validation
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      setError("Invalid file type. Please upload a JPEG, PNG, or GIF image.");
      return;
    }

    // File size validation
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      setError(
        `File size exceeds ${MAX_FILE_SIZE_MB} MB. Please choose a smaller file.`
      );
      return;
    }

    setSelectedFile(file);
    setError(null);
  };

  function printExcel() {
    let xdata = [];

    xdata.push(["รายละเอียด กล่องเอกสาร"]);
    xdata.push([""]);
    xdata.push(["รหัสกล่อง", dataBox2.doc_id]);
    xdata.push(["ชื่อกล่อง", dataBox2.doc_name]);
    xdata.push(["รายละเอียด", dataBox2.doc_detail]);
    xdata.push(["ผู้ปฏิบัติงาน", dataBox2.employ]);
    xdata.push(["หมายเหตุ", dataBox2.remark]);
    xdata.push([
      "วันที่เพิ่มกล่อง",
      moment(dataBox2.start_date).format("DD/MM/YYYY")
    ]);
    xdata.push([
      "วันที่ครบกำหนด",
      moment(dataBox2.end_date).format("DD/MM/YYYY")
    ]);
    xdata.push([
      "ตำแหน่งของกล่อง",
      "ชั้นเก็บเอกสารแถว" +
        url.searchParams.get("page") +
        "| ชั้นที่ " +
        prop.floor
    ]);

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "รายละเอียด กล่องเอกสาร.xlsx");
  }

  function printWord() {
    let xupdated_at = "";

    if (dataBox2.updated_at) {
      xupdated_at = moment(dataBox2.updated_at).format("DD/MM/YYYY HH:mm:ss");
    } else {
      xupdated_at = "";
    }

    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "ชื่อกล่อง\t\t:\t" + dataBox2.doc_name,
                  font: "Cordia New ",
                  size: 32
                }),
                new TextRun({
                  text: "รายละเอียด\t\t:\t" + dataBox2.doc_detail,
                  font: "Cordia New ",
                  size: 32,
                  break: 1
                }),
                new TextRun({
                  text: "ผู้ปฏิบัติงาน\t\t:\t" + dataBox2.employ,
                  font: "Cordia New ",
                  size: 32,
                  break: 1
                }),
                new TextRun({
                  text:
                    "วันที่เพิ่มกล่อง\t\t:\t" +
                    moment(dataBox2.start_date).format("DD/MM/YYYY HH:mm:ss"),
                  font: "Cordia New ",
                  size: 32,
                  break: 1
                }),
                new TextRun({
                  text: "วันที่แก้ไขล่าสุด\t\t:\t" + xupdated_at,
                  font: "Cordia New ",
                  size: 32,
                  break: 1
                }),
                new TextRun({
                  text:
                    "ตำแหน่งของกล่อง\t:\tชั้นเก็บเอกสารแถว " +
                    url.searchParams.get("page") +
                    " | ชั้นที่ " +
                    prop.floor,
                  font: "Cordia New ",
                  size: 32,
                  break: 1
                })
              ]
            })
          ]
        }
      ]
    });

    Packer.toBlob(doc).then(blob => {
      // console.log(blob);
      saveAs(blob, "BoxName.docx");
      // console.log("Document created successfully");
    });
  }

  return (
    <React.Fragment>
      {/* <Button
        variant="contained"
        style={{ fontFamily: "kanit" }}
        onClick={() => {
          printtest();
        }}
      >
        PDF
      </Button> */}
      {/* <Grid container spacing={2}>
        <Grid item xs={5} sm={4} md={2}>
          <Button
            variant="contained"
            style={{ fontFamily: "kanit", marginTop: "10px" }}
            startIcon={<CreateNewFolderIcon />}
            size="small"
            onClick={() => {
              ClickAddDoc();
            }}
          >
            เพิ่มกล่องเอกสาร
          </Button>
        </Grid>
        <Grid item xs={7} sm={8} md={10}>
          <Stack direction="row" spacing={1} sx={{ mt: 1.3, mb: -3 }}>
            <img src={boxicon} alt="CMAQHI" width="30" />
            <p>
              = {Boxuse}/{Boxnum}
            </p>
          </Stack>
        </Grid>
      </Grid> */}
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            {Locker_list}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            {Locker_list2}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            {Locker_list3}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            {Locker_list4}
          </Grid>
        </Grid>
      </Grid>
      <div style={{ float: "right" }}>
        <Stack direction="row" spacing={1} sx={{ mt: 3, mb: 1 }}>
          <img src={boxicon} alt="CMAQHI" width="30" />
          <p>
            = {Boxuse}/{Boxnum}
          </p>
        </Stack>
      </div>
      <TextField
        margin="normal"
        size="small"
        id="search_doc"
        label="ค้นหากล่องเอกสาร"
        name="search_doc"
        // autoFocus
        sx={{ width: 360, mt: 4 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          )
        }}
        onChange={event => {
          searchname(event.target.value);
        }}
        style={{ zIndex: 0 }}
      />

      <ReactDataGrid
        idProperty="id"
        columns={dgcolumns}
        dataSource={dgdataSource}
        style={gridStyle}
        // showZebraRows={false}
        enableClipboard
        scrollProps={scrollProps}
      />
      <div style={{ height: "100px" }} />

      {/* แก้ไข Table */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: 0 }}
      >
        <Card
          sx={{
            maxWidth: "90%",
            maxHeight: "85%",
            overflow: "auto",
            margin: "90px auto",
            float: "none"
          }}
        >
          <CardContent>
            <p style={{ fontSize: "22px", marginTop: "8px" }}>
              แก้ไข กล่องเอกสาร
            </p>
            <hr style={{ marginTop: "0px" }} />

            <Box component="form" onSubmit={handleEdit} noValidate>
              <TextField
                margin="normal"
                fullWidth
                id="doc_id"
                label="รหัสกล่อง"
                name="doc_id"
                defaultValue={dataBox.doc_id}
                InputProps={{
                  readOnly: true
                }}
                variant="filled"
                size="small"
                sx={{ mt: 1 }}
              />

              <TextField
                required
                fullWidth
                margin="normal"
                id="doc_name"
                label="ชื่อกล่องเอกสาร "
                name="doc_name"
                defaultValue={dataBox.doc_name}
                variant="outlined"
                size="small"
                sx={{ mt: 1 }}
              />
              <TextField
                required
                fullWidth
                margin="normal"
                id="doc_detail"
                label="รายละเอียด"
                name="doc_detail"
                defaultValue={dataBox.doc_detail}
                variant="outlined"
                size="small"
                multiline
                rows={3}
                sx={{ mt: 0 }}
              />
              <TextField
                fullWidth
                margin="normal"
                id="remark"
                label="หมายเหตุ"
                name="remark"
                defaultValue={dataBox.remark}
                variant="outlined"
                size="small"
                multiline
                rows={3}
                sx={{ mt: 0 }}
              />
              <TextField
                margin="normal"
                fullWidth
                id="worker1"
                label="ผู้ปฏิบัติงาน"
                name="worker1"
                value={dataBox.employ}
                InputProps={{
                  readOnly: true
                }}
                variant="filled"
                sx={{ mt: 0 }}
              />
              <TextField
                margin="normal"
                required
                id="date_end"
                label="วันที่ครบกำหนด"
                name="date_end"
                type="date"
                defaultValue={moment(dataBox.end_date).format("YYYY-MM-DD")}
                InputLabelProps={{
                  shrink: true
                }}
                size="small"
                sx={{ mt: 0 }}
              />
              <TextField
                margin="normal"
                fullWidth
                id="date1"
                label="วันที่แก้ไขข้อมูล"
                name="date1"
                value={moment().format("DD/MM/YYYY")}
                InputProps={{
                  readOnly: true
                }}
                variant="filled"
                size="small"
                sx={{ mt: 0 }}
              />

              <Box
                p={3}
                border="1px dashed #ccc"
                borderRadius={4}
                textAlign="center"
                sx={{ mt: 1, mb: 2 }}
              >
                แก้ไขรูปภาพ {" "}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="image-file-input"
                />
                <label htmlFor="image-file-input">
                  <Button variant="outlined" component="span">
                    เลือกรูปภาพ
                  </Button>
                </label>
                {selectedFile &&
                  <div>
                    <Typography variant="subtitle1" mt={1}>
                      Selected Image: {selectedFile.name}
                    </Typography>
                  </div>}
                {error &&
                  <Typography variant="body2" color="error" mt={2}>
                    {error}
                  </Typography>}
              </Box>

              <hr style={{ marginBottom: "-5px" }} />
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  float: "right"
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  style={{ fontFamily: "kanit" }}
                  onClick={() => {
                    deleteDoc();
                  }}
                >
                  ลบข้อมูล
                </Button>{" "}
                <Button
                  variant="contained"
                  startIcon={<EditIcon />}
                  style={{ fontFamily: "kanit" }}
                  type="submit"
                >
                  แก้ไขข้อมูล
                </Button>
              </div>

              <div style={{ height: "50px" }} />
            </Box>
          </CardContent>
        </Card>
      </Modal>

      {/* รายละเอียด Table */}
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: 0 }}
      >
        <Card
          sx={{
            maxWidth: "90%",
            maxHeight: "85%",
            overflow: "auto",
            margin: "90px auto",
            float: "none"
          }}
        >
          <CardContent>
            <p style={{ fontSize: "22px", marginTop: "8px" }}>
              รายละเอียด กล่องเอกสาร
            </p>
            <hr style={{ marginTop: "0px" }} />
            <Grid container>
              <Grid item xs={2}>
                <p>รหัสกล่อง :</p>
              </Grid>
              <Grid item xs={10}>
                <p>
                  {dataBox2.doc_id}
                </p>
              </Grid>
              <Grid item xs={2}>
                <p>ชื่อกล่อง :</p>
              </Grid>
              <Grid item xs={10}>
                <p>
                  {dataBox2.doc_name}
                </p>
              </Grid>
              <Grid item xs={2}>
                <p>รายละเอียด :</p>
              </Grid>
              <Grid item xs={10}>
                <p>
                  {dataBox2.doc_detail}
                </p>
              </Grid>
              <Grid item xs={2}>
                <p>ผู้ปฏิบัติงาน :</p>
              </Grid>
              <Grid item xs={10}>
                <p>
                  {dataBox2.employ}
                </p>
              </Grid>
              <Grid item xs={2}>
                <p>หมายเหตุ :</p>
              </Grid>
              <Grid item xs={10}>
                <p>
                  {dataBox2.remark}
                </p>
              </Grid>
              <Grid item xs={2}>
                <p>วันที่เพิ่มกล่อง :</p>
              </Grid>
              <Grid item xs={10}>
                <p>
                  {moment(dataBox2.start_date).format("DD/MM/YYYY")}
                </p>
              </Grid>
              <Grid item xs={2}>
                <p>วันที่ครบกำหนด :</p>
              </Grid>
              <Grid item xs={10}>
                {dataBox2.end_date
                  ? <p>
                      {moment(dataBox2.end_date).format("DD/MM/YYYY")}
                    </p>
                  : <p />}
              </Grid>
              <Grid item xs={2}>
                <p>ตำแหน่งของกล่อง :</p>
              </Grid>
              <Grid item xs={10}>
                <p>
                  ชั้นเก็บเอกสารแถว {url.searchParams.get("page")} | ชั้นที่{" "}
                  {prop.floor}
                </p>
              </Grid>
              <Grid item xs={2}>
                <p>ไฟล์รูป :</p>
              </Grid>
              <Grid item xs={10}>
                {dataBox2.file_name
                  ? <a
                      style={{ textDecoration: "none" }}
                      href={xURL + "op_imgs/" + dataBox2.file_name}
                      target="_blank"
                    >
                      <p>ไฟล์รูป</p>
                    </a>
                  : <p>-</p>}
              </Grid>
            </Grid>

            <hr style={{ marginBottom: "-5px" }} />

            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                float: "right"
              }}
            >
              {User_Level == "1" || User_Level == "99"
                ? <Button
                    variant="contained"
                    color="error"
                    startIcon={<FolderDeleteIcon />}
                    style={{ fontFamily: "kanit" }}
                    onClick={() => {
                      destroyDoc();
                    }}
                  >
                    ทำลายข้อมูล
                  </Button>
                : <div />}{" "}
              {dataBox2.status == 1
                ? <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ManageHistoryIcon />}
                    style={{ fontFamily: "kanit" }}
                    onClick={() => {
                      window.open(
                        "/Archive/doc_borrow?doc_id=" +
                          dataBox2.doc_id +
                          "&c=" +
                          url.searchParams.get("category") +
                          "&b=" +
                          url.searchParams.get("box"),
                        "_self"
                      );
                    }}
                  >
                    ยืมกล่องเอกสาร
                  </Button>
                : <Button
                    variant="contained"
                    color="success"
                    startIcon={<ReplyAllIcon />}
                    style={{ fontFamily: "kanit" }}
                    onClick={() => {
                      Doc_receive(dataBox2.doc_id);
                    }}
                  >
                    คืนกล่องเอกสาร
                  </Button>}{" "}
              <Button
                variant="outlined"
                startIcon={<Icon icon="vscode-icons:file-type-excel2" />}
                style={{ fontFamily: "kanit" }}
                // type="submit"
                onClick={() => {
                  printExcel();
                }}
              >
                Excel
              </Button>{" "}
              <Button
                variant="text"
                style={{ fontFamily: "kanit" }}
                onClick={() => {
                  setOpen2(false);
                }}
              >
                close
              </Button>
            </div>

            <div style={{ height: "50px" }} />
          </CardContent>
        </Card>
      </Modal>
    </React.Fragment>
  );
};

export default Doc_storage_list;
