import React, { useState, useEffect } from "react";
import axios from "axios";

import moment from "moment";

import {
  Container,
  TextField,
  InputAdornment,
  Modal,
  Card,
  CardContent,
  Grid,
  Button
} from "@mui/material";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { Icon } from "@iconify/react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import * as xlsx from "xlsx";

const Doc_search = () => {
  var url = new URL(window.location.href);

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3001/api/";
  } else {
    xURL = "https://finance.rihes.cmu.ac.th/api/";
  }
  const [GridHeight, setGridHeight] = useState(643);

  const [dgdataSource, setdgdataSource] = useState([]);
  const [dgdataSourceEx, setdgdataSourceEx] = useState([]);
  const [dataBox, setdataBox] = useState([""]);

  const [Position, setPosition] = useState("");

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleOpen = zdata => {
    let xpst = "";

    if (zdata.doc_id.toString().substring(1, 2) == "0") {
      xpst =
        "ชั้นเอกสารแถว" +
        zdata.doc_id.toString().substring(0, 1) +
        " | ชั้นที่ " +
        zdata.doc_id.toString().substring(2, 3);
    } else {
      xpst =
        "ชั้นเอกสารแถว" +
        zdata.doc_id.toString().substring(0, 1) +
        " | ราวที่ " +
        zdata.doc_id.toString().substring(1, 2) +
        " | ชั้นที่ " +
        zdata.doc_id.toString().substring(2, 3);
    }

    setPosition(xpst);
    setdataBox(zdata);
    setOpen(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          getData();
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/Archive/login";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const getData = boxname => {
    let xapi = "";

    if (!boxname) {
      xapi = "getData_all";
    } else {
      xapi = "getFindData_all";
    }

    axios
      .post(xURL + xapi, {
        boxname: boxname
      })
      .then(result => {
        const Tb1 = [],
          Tb2 = [];

        let xend_date = "",
          xheight = 643,
          xfile = "";

        result.data.forEach((i, idx) => {
          if (i.end_date) {
            xend_date = moment(i.end_date).format("DD/MM/YYYY");
          } else {
            xend_date = "";
          }

          if (i.file_id) {
            xfile = "ไฟล์รูป";
          } else {
            xfile = "";
          }

          Tb1.push({
            no: parseInt(idx + 1),
            doc_id: (
              <a
                style={{ color: "#525CEB" }}
                onClick={() => {
                  handleOpen(i);
                }}
              >
                {i.doc_id}
              </a>
            ),
            doc_name: (
              <a
                style={{ color: "#525CEB" }}
                onClick={() => {
                  handleOpen(i);
                }}
              >
                {i.doc_name}
              </a>
            ),
            doc_detail: i.doc_detail,
            remark: i.remark,
            date_receive: moment(i.start_date).format("DD/MM/YYYY"),
            date_end: xend_date,
            worker: i.employ,
            file: (
              <a
                style={{ textDecoration: "none" }}
                href={xURL + "op_imgs/" + i.file_name}
                target="_blank"
              >
                {xfile}
              </a>
            ),
            file_name: i.file_name,
            status: i.status_name
          });

          Tb2.push({
            doc_id: i.doc_id,
            doc_name: i.doc_name,
            doc_detail: i.doc_detail,
            remark: i.remark,
            worker: i.employ,
            date_receive: moment(i.start_date).format("DD/MM/YYYY"),
            date_end: xend_date
          });

          if (idx > 14) {
            xheight = xheight + 40;
          }
        });

        setdgdataSource(Tb1);
        setdgdataSourceEx(Tb2);
        setGridHeight(xheight);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const printExcel = () => {
    let xdata = [];

    xdata.push(["รายละเอียด กล่องเอกสาร"]);
    xdata.push([""]);
    xdata.push(["รหัสกล่อง", dataBox.doc_id]);
    xdata.push(["ชื่อกล่อง", dataBox.doc_name]);
    xdata.push(["รายละเอียด", dataBox.doc_detail]);
    xdata.push(["ผู้ปฏิบัติงาน", dataBox.employ]);
    xdata.push(["หมายเหตุ", dataBox.remark]);
    xdata.push([
      "วันที่เพิ่มกล่อง",
      moment(dataBox.start_date).format("DD/MM/YYYY")
    ]);
    xdata.push([
      "วันที่ครบกำหนด",
      moment(dataBox.end_date).format("DD/MM/YYYY")
    ]);
    xdata.push(["ตำแหน่งของกล่อง", Position]);

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "รายละเอียด กล่องเอกสาร.xlsx");
  };

  const excelclick = () => {
    let xdata = [];
    let header = [
      "รหัสกล่อง",
      "ชื่อกล่องเอกสาร",
      "รายละเอียด",
      "หมายเหตุ",
      "ผู้ปฏิบัติงาน",
      "วันที่เพิ่มกล่อง",
      "วันที่ครบกำหนด"
    ];

    xdata.push(["กล่องเอกสารทั้งหมด"]);
    xdata.push([""]);
    xdata.push(header);

    for (var row in dgdataSourceEx) {
      const xx = [];

      for (var col in dgdataSourceEx[row]) {
        xx.push(dgdataSourceEx[row][col]);
      }

      xdata.push(xx);
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "กล่องเอกสารทั้งหมด.xlsx");
  };

  const dgcolumns = [
    {
      name: "no",
      header: "ที่",
      maxWidth: 70,
      minWidth: 50,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "doc_id",
      header: "รหัสกล่อง",
      maxWidth: 130,
      minWidth: 100,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "doc_name",
      header: "ชื่อกล่องเอกสาร",
      maxWidth: 300,
      minWidth: 120,
      defaultFlex: 1,
      headerAlign: "center",
      //   textAlign: "center",
      sortable: false
    },
    {
      name: "doc_detail",
      header: "รายละเอียด",
      maxWidth: 450,
      minWidth: 120,
      defaultFlex: 1,
      headerAlign: "center",
      //   textAlign: "center",
      sortable: false
    },
    {
      name: "remark",
      header: "หมายเหตุ",
      maxWidth: 300,
      minWidth: 100,
      defaultFlex: 1,
      headerAlign: "center",
      sortable: false
    },
    {
      name: "worker",
      header: "ผู้ปฏิบัติงาน",
      maxWidth: 220,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "date_receive",
      header: "วันที่เพิ่มกล่อง",
      maxWidth: 170,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "date_end",
      header: "วันที่ครบกำหนด",
      maxWidth: 170,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "file",
      header: "ไฟล์",
      maxWidth: 90,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "status",
      header: "สถานะ",
      maxWidth: 90,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false,
      onRender: (cellProps, { data }) => {
        cellProps.style.background = data.status == "ยืม" ? "red" : "";
        cellProps.style.color = data.status == "ยืม" ? "#fff" : "";
      }
    }
  ];

  const gridStyle = { minHeight: GridHeight, marginTop: "10px" };

  const scrollProps = Object.assign(
    {},
    ReactDataGrid.defaultProps.scrollProps,
    {
      autoHide: false
    }
  );

  const searchname = values => {
    // console.log(values);
    getData(values);
  };

  return (
    <React.Fragment>
      <Navbar f_id={localStorage.getItem("f_id")} />

      <Container>
        <p style={{ fontSize: "24px", marginTop: "120px" }}>
          กล่องเอกสารทั้งหมด
        </p>
        <hr />

        <TextField
          margin="normal"
          size="small"
          id="search_doc"
          label="ค้นหากล่องเอกสาร"
          name="search_doc"
          // autoFocus
          sx={{ width: 360, mt: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          onChange={event => {
            searchname(event.target.value);
          }}
          style={{ zIndex: 0 }}
        />

        <div style={{ float: "right" }}>
          <Button
            variant="outlined"
            style={{ fontFamily: "kanit" }}
            sx={{ mt: 3 }}
            startIcon={<Icon icon="vscode-icons:file-type-excel2" />}
            onClick={() => {
              excelclick();
            }}
          >
            Export Excel
          </Button>
        </div>

        <ReactDataGrid
          idProperty="id"
          columns={dgcolumns}
          dataSource={dgdataSource}
          style={gridStyle}
          // showZebraRows={false}
          enableClipboard
          scrollProps={scrollProps}
        />

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ zIndex: 0 }}
        >
          <Card
            sx={{
              maxWidth: 1000,
              maxHeight: 750,
              overflow: "auto",
              margin: "100px auto",
              float: "none"
            }}
          >
            <CardContent>
              <p style={{ fontSize: "22px", marginTop: "8px" }}>
                รายละเอียด กล่องเอกสาร
              </p>
              <hr style={{ marginTop: "0px" }} />
              <Grid container>
                <Grid item xs={2}>
                  <p>รหัสกล่อง :</p>
                </Grid>
                <Grid item xs={10}>
                  <p>
                    {dataBox.doc_id}
                  </p>
                </Grid>
                <Grid item xs={2}>
                  <p>ชื่อกล่อง :</p>
                </Grid>
                <Grid item xs={10}>
                  <p>
                    {dataBox.doc_name}
                  </p>
                </Grid>
                <Grid item xs={2}>
                  <p>รายละเอียด :</p>
                </Grid>
                <Grid item xs={10}>
                  <p>
                    {dataBox.doc_detail}
                  </p>
                </Grid>
                <Grid item xs={2}>
                  <p>ผู้ปฏิบัติงาน :</p>
                </Grid>
                <Grid item xs={10}>
                  <p>
                    {dataBox.employ}
                  </p>
                </Grid>
                <Grid item xs={2}>
                  <p>หมายเหตุ :</p>
                </Grid>
                <Grid item xs={10}>
                  <p>
                    {dataBox.remark}
                  </p>
                </Grid>
                <Grid item xs={2}>
                  <p>วันที่เพิ่มกล่อง :</p>
                </Grid>
                <Grid item xs={10}>
                  <p>
                    {moment(dataBox.start_date).format("DD/MM/YYYY")}
                  </p>
                </Grid>
                <Grid item xs={2}>
                  <p>วันที่ครบกำหนด :</p>
                </Grid>
                <Grid item xs={10}>
                  {dataBox.end_date
                    ? <p>
                        {moment(dataBox.end_date).format("DD/MM/YYYY")}
                      </p>
                    : <p />}
                </Grid>
                <Grid item xs={2}>
                  <p>ตำแหน่งของกล่อง :</p>
                </Grid>
                <Grid item xs={10}>
                  <p>
                    {Position}
                  </p>
                </Grid>
                <Grid item xs={2}>
                  <p>ไฟล์รูป :</p>
                </Grid>
                <Grid item xs={10}>
                  {dataBox.file_name
                    ? <a
                        style={{ textDecoration: "none" }}
                        href={xURL + "op_imgs/" + dataBox.file_name}
                        target="_blank"
                      >
                        <p>ไฟล์รูป</p>
                      </a>
                    : <p>-</p>}
                </Grid>
              </Grid>

              <hr style={{ marginBottom: "-5px" }} />

              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  float: "right"
                }}
              >
                <Button
                  variant="text"
                  style={{ fontFamily: "kanit" }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  close
                </Button>{" "}
                <Button
                  variant="outlined"
                  startIcon={<Icon icon="vscode-icons:file-type-excel2" />}
                  style={{ fontFamily: "kanit" }}
                  // type="submit"
                  onClick={() => {
                    printExcel();
                  }}
                >
                  Excel
                </Button>
              </div>
            </CardContent>
          </Card>
        </Modal>
      </Container>

      <div style={{ height: "100px" }} />
      <Footer />
    </React.Fragment>
  );
};

export default Doc_search;
