import React, { useEffect, useState } from "react";
import axios from "axios";

import moment from "moment";

import { Container, Grid, Box, TextField, Button } from "@mui/material";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import Swal from "sweetalert2";

const Doc_borrow = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3001/api/";
  } else {
    xURL = "https://finance.rihes.cmu.ac.th/api/";
  }

  var url = new URL(window.location.href);
  let xdoc_id = url.searchParams.get("doc_id");

  const [Docdetail, setDocdetail] = useState([]);
  const [Employ, setEmploy] = useState("");

  const [GridHeight, setGridHeight] = useState(323);
  const [dgdataSource, setdgdataSource] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          getHeadData();
          showHistory();
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/Archive/login";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const getHeadData = () => {
    axios
      .post(xURL + "getHborrow", { doc_id: xdoc_id })
      .then(result => {
        // console.log(result.data.length);
        // console.log(result.data);
        setDocdetail(result.data[0]);
        setEmploy(result.data[0].employ);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const showHistory = () => {
    axios
      .post(xURL + "getHtrBorrow", { doc_id: xdoc_id })
      .then(result => {
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          Tb1.push({
            no: parseInt(idx + 1),
            borrow_name: i.borrow_name,
            doc_detail: i.borrow_detail,
            employ_name: i.emp_borrow,
            date_borrow: moment(i.borrow_date).format("DD/MM/YYYY"),
            date_receive: moment(i.receive_date).format("DD/MM/YYYY"),
            employ_keep: i.emp_receive
          });
        });

        setdgdataSource(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!data.get("borrowname")) {
      Swal.fire({
        title: "บันทึกไม่ได้!",
        text: "ยังไม่ได้คีย์ ชื่อผู้ยืม",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else if (!data.get("remark")) {
      Swal.fire({
        title: "บันทึกไม่ได้!",
        text: "ยังไม่ได้คีย์ รายละเอียดการยืม",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else {
      Getborrow(event);
    }
  };

  const Getborrow = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    Swal.fire({
      title: "ยืนยัน ยืมกล่องเอกสาร",
      html:
        "รหัสกล่อง " +
        Docdetail.doc_id +
        `<br > ผู้ยืม ` +
        data.get("borrowname"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก"
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .post(xURL + "insertBorrow", {
            box_id: Docdetail.doc_id,
            borrowname: data.get("borrowname"),
            borrowdetail: data.get("remark"),
            borrowdate: moment().format("YYYY-MM-DD"),
            borrowemp: data.get("employname")
          })
          .then(result => {
            Swal.fire({
              title: "ยืมสำเร็จ!",
              text: "^_^",
              icon: "success",
              showConfirmButton: false,
              timer: 2000
            }).then(result => {
              window.open(
                "/Archive/doc?page=" +
                  Docdetail.doc_id.toString().substring(0, 1) +
                  "&category=" +
                  url.searchParams.get("c") +
                  "&box=" +
                  url.searchParams.get("b") +
                  "&floor=" +
                  Docdetail.doc_id.toString().substring(2, 3),
                "_self"
              );
            });
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      }
    });
  };

  const dgcolumns = [
    {
      name: "no",
      header: "ที่",
      maxWidth: 70,
      minWidth: 50,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "borrow_name",
      header: "ชื่อผู้ยืม",
      maxWidth: 250,
      minWidth: 120,
      defaultFlex: 1,
      headerAlign: "center",
      sortable: false
    },
    {
      name: "doc_detail",
      header: "รายละเอียด",
      maxWidth: 300,
      minWidth: 120,
      defaultFlex: 1,
      headerAlign: "center",
      sortable: false
    },
    {
      name: "employ_name",
      header: "ชื่อผู้ให้ยืม",
      maxWidth: 250,
      minWidth: 120,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "date_borrow",
      header: "วันที่ยืม",
      maxWidth: 150,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "date_receive",
      header: "วันที่คืน",
      maxWidth: 150,
      minWidth: 70,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    },
    {
      name: "employ_keep",
      header: "ผู้รับคืน",
      maxWidth: 250,
      minWidth: 120,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false
    }
  ];

  const gridStyle = { minHeight: GridHeight, marginTop: "10px" };

  const scrollProps = Object.assign(
    {},
    ReactDataGrid.defaultProps.scrollProps,
    {
      autoHide: false
    }
  );

  return (
    <React.Fragment>
      <Container>
        <p style={{ fontSize: "24px", marginTop: "50px" }}>ยืมกล่องเอกสาร</p>
        <hr />

        <Grid container>
          <Grid item xs={3} sm={2} md={1} sx={{ mt: -1 }}>
            <p>รหัสกล่อง :</p>
          </Grid>
          <Grid item xs={9} sm={10} md={11} sx={{ mt: -1 }}>
            <p>
              {Docdetail.doc_id}
            </p>
          </Grid>
          <Grid item xs={3} sm={2} md={1} sx={{ mt: -3 }}>
            <p>ชื่อกล่อง :</p>
          </Grid>
          <Grid item xs={9} sm={10} md={11} sx={{ mt: -3 }}>
            <p>
              {Docdetail.doc_name}
            </p>
          </Grid>
        </Grid>

        <hr />

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            id="borrowname"
            label="ชื่อผู้ยืม"
            name="borrowname"
            margin="normal"
            required
            fullWidth
            size="small"
            autoFocus
          />

          <TextField
            id="remark"
            label="รายละเอียดการยืม"
            name="remark"
            required
            fullWidth
            size="small"
            multiline
            rows={3}
          />

          <TextField
            margin="normal"
            fullWidth
            id="dateBorrow"
            label="วันที่ยืม"
            name="dateBorrow"
            value={moment().format("DD/MM/YYYY")}
            InputProps={{
              readOnly: true
            }}
            size="small"
            variant="filled"
            helperText="* วันที่ ปัจจุบัน"
          />

          <TextField
            margin="normal"
            fullWidth
            id="employname"
            label="ผู้ให้ยืม"
            name="employname"
            value={Employ}
            InputProps={{
              readOnly: true
            }}
            size="small"
            variant="filled"
          />

          <hr />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            ยืนยัน
          </Button>
        </Box>

        <p style={{ fontSize: "24px", marginTop: "50px" }}>ประวัติยืม-คืน</p>
        <hr />

        <ReactDataGrid
          idProperty="id"
          columns={dgcolumns}
          dataSource={dgdataSource}
          style={gridStyle}
          // showZebraRows={false}
          enableClipboard
          scrollProps={scrollProps}
        />

        <div style={{ height: "100px" }} />
      </Container>
    </React.Fragment>
  );
};

export default Doc_borrow;
