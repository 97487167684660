import React, { useEffect } from "react";
import axios from "axios";

import {
  Container,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia
} from "@mui/material";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import pics_storage from "../imgs/storage.png";
import pics_bin from "../imgs/bin.jpg";
import pics_search from "../imgs/search.png";

import "./css/Home.css";

const Home = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3001/api/";
  } else {
    xURL = "https://finance.rihes.cmu.ac.th/api/";
  }

  // console.log(xURL);

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/Archive/login";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const DocPage = (page, category, box) => {
    if (page == 1 || page == 4) {
      window.open(
        "/Archive/doc?page=" + page + "&category=" + category + "&box=" + box,
        "_self"
      );
    } else if (page == 2 || page == 3) {
      window.open(
        "/Archive/doc2?page=" + page + "&category=" + category + "&box=" + box,
        "_self"
      );
    }
  };

  return (
    <React.Fragment>
      <Navbar f_id={localStorage.getItem("f_id")} />

      <Container>
        <Box sx={{ flexGrow: 1, mt: 20 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={3}>
              <Card
                sx={{ maxWidth: 345 }}
                className="card-style"
                onClick={() => {
                  DocPage(1, 7, 18);
                }}
              >
                <CardMedia
                  sx={{ height: 400 }}
                  image={pics_storage}
                  title="file01"
                />
                <CardContent>ชั้นเก็บเอกสารแถว 1</CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Card
                sx={{ maxWidth: 345 }}
                className="card-style"
                onClick={() => {
                  DocPage(2, 6, 16);
                }}
              >
                <CardMedia
                  sx={{ height: 400 }}
                  image={pics_storage}
                  title="file02"
                />
                <CardContent>ชั้นเก็บเอกสารแถว 2</CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Card
                sx={{ maxWidth: 345 }}
                className="card-style"
                onClick={() => {
                  DocPage(3, 6, 16);
                }}
              >
                <CardMedia
                  sx={{ height: 400 }}
                  image={pics_storage}
                  title="file03"
                />
                <CardContent>ชั้นเก็บเอกสารแถว 3</CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Card
                sx={{ maxWidth: 345 }}
                className="card-style"
                onClick={() => {
                  DocPage(4, 7, 18);
                }}
              >
                <CardMedia
                  sx={{ height: 400 }}
                  image={pics_storage}
                  title="file04"
                />
                <CardContent>ชั้นเก็บเอกสารแถว 4</CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Card
                sx={{ maxWidth: 345 }}
                className="card-style"
                onClick={() => {
                  window.open("/Archive/doc_destroy", "_self");
                }}
              >
                <CardMedia
                  sx={{ height: 400 }}
                  image={pics_bin}
                  title="file-destroy"
                />
                <CardContent>เอกสารทำลาย</CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Card
                sx={{ maxWidth: 345 }}
                className="card-style"
                onClick={() => {
                  window.open("/Archive/doc_search", "_self");
                }}
              >
                <CardMedia
                  sx={{ height: 400 }}
                  image={pics_search}
                  title="file-destroy"
                />
                <CardContent>ค้นหาเอกสารทั้งหมด</CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <div style={{ height: "100px" }} />
      <Footer />
    </React.Fragment>
  );
};
export default Home;
