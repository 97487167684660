import React, { useState, useEffect } from "react";
import axios from "axios";

import { AppBar, Box, Toolbar, IconButton, Typography } from "@mui/material";

import "./css/Navbar.css";

import logo from "../imgs/archive.png";
import LogoutIcon from "@mui/icons-material/Logout";

import Swal from "sweetalert2";

const Navbar = props => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3001/api/";
  } else {
    xURL = "https://finance.rihes.cmu.ac.th/api/";
  }

  const [f_name, setf_name] = useState();

  useEffect(() => {
    // console.log(props.research_id);

    axios
      .post(xURL + "findname", { finance_id: props.f_id })
      .then(result => {
        // console.log(result.data.length);
        if (result.data.length > 0) {
          setf_name(result.data[0].fname);
        } else {
          setf_name("");
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  return (
    <div className="Navbar">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          style={{ backgroundColor: "black", opacity: 0.9 }}
        >
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <h4 sx={{ flexGrow: 1 }}>
                <a href="/Archive">
                  <img src={logo} alt="CMAQHI" width="25" /> ห้องเก็บเอกสาร
                  Archive Room
                </a>
              </h4>
            </Box>

            <a>
              สวัสดี {f_name}
            </a>

            <IconButton sx={{ color: "white" }}>
              <LogoutIcon
                fontSize="inherit"
                onClick={() => {
                  Swal.fire({
                    title: "ออกจากระบบ",
                    text: "☹",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "ยืนยัน",
                    cancelButtonText: "ยกเลิก"
                  }).then(result => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "ออกจากระบบ!",
                        text: "ออกจากระบบ สำเร็จ",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000,
                        customClass: {
                          container: "my-swal"
                        }
                      }).then(() => {
                        localStorage.removeItem("token");
                        window.location = "/Archive/login";
                      });
                    }
                  });
                }}
              />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};
export default Navbar;
