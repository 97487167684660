import React, { useState, useEffect } from "react";
import axios from "axios";

import PropTypes from "prop-types";
import { Container, Tabs, Tab, Box } from "@mui/material";

import KitchenIcon from "@mui/icons-material/Kitchen";

import Doc_storage_list from "../components/Doc_storege_list";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        <Box sx={{ p: 2 }}>
          {children}
        </Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Doc_Storage = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3001/api/";
  } else {
    xURL = "https://finance.rihes.cmu.ac.th/api/";
  }

  const [Docroom, setDocroom] = useState("");
  const [Category, setCategory] = useState();
  const [Boxnum, setBoxnum] = useState();

  const [value, setValue] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          var url = new URL(window.location.href);

          if (url.searchParams.get("floor")) {
            setValue(parseInt(url.searchParams.get("floor")) - 1);
          }

          setDocroom(url.searchParams.get("page"));
          setCategory(url.searchParams.get("category"));
          setBoxnum(url.searchParams.get("box"));
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/Archive/login";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Navbar f_id={localStorage.getItem("f_id")} />

      <Container>
        <p style={{ fontSize: "24px", marginTop: "120px" }}>
          ชั้นเก็บเอกสารแถว {Docroom}
        </p>
        <hr />

        <Box sx={{ width: "100%" }}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginTop: "0px" }}
          >
            <Tabs
              value={value}
              onChange={TabChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              <Tab
                icon={<KitchenIcon />}
                label={<span style={{ fontFamily: "kanit" }}>ชั้นที่ 1</span>}
                {...a11yProps(0)}
              />
              <Tab
                icon={<KitchenIcon />}
                label={<span style={{ fontFamily: "kanit" }}>ชั้นที่ 2</span>}
                {...a11yProps(1)}
              />
              <Tab
                icon={<KitchenIcon />}
                label={<span style={{ fontFamily: "kanit" }}>ชั้นที่ 3</span>}
                {...a11yProps(2)}
              />
              <Tab
                icon={<KitchenIcon />}
                label={<span style={{ fontFamily: "kanit" }}>ชั้นที่ 4</span>}
                {...a11yProps(3)}
              />
              <Tab
                icon={<KitchenIcon />}
                label={<span style={{ fontFamily: "kanit" }}>ชั้นที่ 5</span>}
                {...a11yProps(4)}
              />
              <Tab
                icon={<KitchenIcon />}
                label={<span style={{ fontFamily: "kanit" }}>ชั้นที่ 6</span>}
                {...a11yProps(5)}
              />
              <Tab
                icon={<KitchenIcon />}
                label={<span style={{ fontFamily: "kanit" }}>ชั้นที่ 7</span>}
                {...a11yProps(6)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Doc_storage_list floor={value + 1} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Doc_storage_list floor={value + 1} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Doc_storage_list floor={value + 1} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Doc_storage_list floor={value + 1} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Doc_storage_list floor={value + 1} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Doc_storage_list floor={value + 1} />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Doc_storage_list floor={value + 1} />
          </TabPanel>
        </Box>
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default Doc_Storage;
