import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Firstpage from "./pages/Firstpage";
import Home from "./pages/Home";
import Doc_Storage from "./pages/Doc_Storage";
import Doc_Storage2 from "./pages/Doc_Storage2";
import Add_doc from "./pages/Add_doc";
import Add_doc2 from "./pages/Add_doc2";
import Doc_destroy from "./pages/Doc_destroy";
import Doc_search from "./pages/Doc_search";
import Login from "./pages/Login";
import Doc_borrow from "./pages/Doc_borrow";
import Change_pass from "./pages/Change_pass";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Firstpage />} />

        <Route path="/Archive/login" element={<Login />} />
        <Route path="/Archive" element={<Home />} />
        <Route path="/Archive/doc" element={<Doc_Storage />} />
        <Route path="/Archive/doc2" element={<Doc_Storage2 />} />
        <Route path="/Archive/adddoc" element={<Add_doc />} />
        <Route path="/Archive/adddoc2" element={<Add_doc2 />} />
        <Route path="/Archive/doc_destroy" element={<Doc_destroy />} />
        <Route path="/Archive/doc_search" element={<Doc_search />} />
        <Route path="/Archive/doc_borrow" element={<Doc_borrow />} />
        <Route path="/Archive/changeP" element={<Change_pass />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
