import React, { useEffect, useState } from "react";
import axios from "axios";

import moment from "moment";

import { Container, Box, TextField, Button, Typography } from "@mui/material";

import Swal from "sweetalert2";

const MAX_FILE_SIZE_MB = 5;
const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/gif"];

const Add_doc = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3001/api/";
  } else {
    xURL = "https://finance.rihes.cmu.ac.th/api/";
  }
  
  const [Docroom, setDocroom] = useState("");
  const [Floor, setFloor] = useState();
  const [Category, setCategory] = useState();
  const [Boxnum, setBoxnum] = useState();

  const [BoxIdName, setBoxIdName] = useState("");
  const [BoxId, setBoxId] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);

  const [f_name, setf_name] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          var url = new URL(window.location.href);

          setDocroom(url.searchParams.get("page"));
          setFloor(url.searchParams.get("floor"));
          setCategory(url.searchParams.get("c"));
          setBoxnum(url.searchParams.get("b"));
          setBoxIdName(url.searchParams.get("lock"));

          var xlock = "";
          if (url.searchParams.get("lock") < 10) {
            xlock = 0 + url.searchParams.get("lock");
          } else {
            xlock = url.searchParams.get("lock");
          }

          setBoxId(
            url.searchParams.get("page") +
              0 +
              url.searchParams.get("floor") +
              xlock
          );

          getworker();
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/Archive/login";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const getworker = () => {
    axios
      .post(xURL + "findname", { finance_id: localStorage.getItem("f_id") })
      .then(result => {
        // console.log(result.data.length);
        if (result.data.length > 0) {
          setf_name(result.data[0].fname + " " + result.data[0].lname);
        } else {
          setf_name("");
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!data.get("boxname")) {
      Swal.fire({
        title: "บันทึกไม่ได้!",
        text: "ยังไม่ได้คีย์ ชื่อกล่องเอกสาร",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else if (!data.get("boxdetail")) {
      Swal.fire({
        title: "บันทึกไม่ได้!",
        text: "ยังไม่ได้คีย์ รายละเอียดกล่องเอกสาร",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else if (!data.get("date_end")) {
      Swal.fire({
        title: "แก้ไขไม่ได้!",
        text: "ยังไม่ได้คีย์ วันที่ครบกำหนด",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000
      });
    } else {
      // console.log(WorkNameSelect);
      insertBox(event);
    }
  };

  const insertBox = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // console.log(
    //   parseInt(Floor),
    //   data.get("boxname"),
    //   data.get("boxdetail"),
    //   moment().format("YYYY-MM-DD HH:mm:ss"),
    //   WorkNameSelect
    // );
    // console.log(data.get("date_end"));

    const formData = new FormData();
    formData.append("file", selectedFile);

    axios.post(xURL + "uploadfile", formData).then(res => {
      //Now do what you want with the response;
      //console.log(res.data.filename)

      axios
        .post(xURL + "insertBox", {
          box_id: BoxId,
          boxname: data.get("boxname"),
          boxdetail: data.get("boxdetail"),
          remark: data.get("remark"),
          start_date: moment().format("YYYY-MM-DD HH:mm:ss"),
          end_date: data.get("date_end"),
          workname: f_name,
          room: Docroom,
          f_name: res.data.filename
        })
        .then(result => {
          Swal.fire({
            title: "บันทึกสำเร็จ!",
            text: "^_^",
            icon: "success",
            showConfirmButton: false,
            timer: 2000
          }).then(result => {
            window.open(
              "/Archive/doc?page=" +
                Docroom +
                "&category=" +
                Category +
                "&box=" +
                Boxnum +
                "&floor=" +
                Floor,
              "_self"
            );
          });
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    });
  };

  const handleFileChange = event => {
    const file = event.target.files[0];

    // File type validation
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      setError("Invalid file type. Please upload a JPEG, PNG, or GIF image.");
      return;
    }

    // File size validation
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      setError(
        `File size exceeds ${MAX_FILE_SIZE_MB} MB. Please choose a smaller file.`
      );
      return;
    }

    setSelectedFile(file);
    setError(null);
  };

  // const handleUpload = () => {
  //   if (selectedFile) {
  //     const formData = new FormData();
  //     formData.append("file", selectedFile);

  //     console.log("Uploading file...", formData);
  //   } else {
  //     console.error("No file selected");
  //   }
  // };

  return (
    <React.Fragment>
      <Container>
        <p style={{ fontSize: "24px", marginTop: "50px" }}>
          เพิ่มกล่องเอกสาร ชั้นเก็บเอกสารแถว {Docroom} ชั้นที่ {Floor} ล็อกที่{" "}
          {BoxIdName}
        </p>
        <hr />

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            id="box_id"
            label="รหัสกล่อง"
            name="box_id"
            value={BoxId}
            InputProps={{
              readOnly: true
            }}
            variant="filled"
          />

          <TextField
            id="boxname"
            label="ชื่อกล่องเอกสาร"
            name="boxname"
            margin="normal"
            required
            fullWidth
            size="small"
            autoFocus
          />

          <TextField
            id="boxdetail"
            label="รายละเอียดกล่องเอกสาร"
            name="boxdetail"
            required
            fullWidth
            size="small"
            multiline
            rows={4}
          />

          <TextField
            id="remark"
            label="หมายเหตุ"
            name="remark"
            margin="normal"
            fullWidth
            size="small"
            multiline
            rows={3}
          />

          <TextField
            margin="normal"
            fullWidth
            id="date1"
            label="วันที่เพิ่มกล่อง"
            name="date1"
            value={moment().format("DD/MM/YYYY")}
            InputProps={{
              readOnly: true
            }}
            variant="filled"
          />

          <TextField
            margin="normal"
            required
            id="date_end"
            label="วันที่ครบกำหนด"
            name="date_end"
            type="date"
            InputLabelProps={{
              shrink: true
            }}
          />

          <TextField
            margin="normal"
            fullWidth
            id="worker1"
            label="ผู้ปฏิบัติงาน"
            name="worker1"
            value={f_name}
            InputProps={{
              readOnly: true
            }}
            variant="filled"
          />

          <Box
            p={3}
            border="1px dashed #ccc"
            borderRadius={4}
            textAlign="center"
            sx={{ mt: 1, mb: 2 }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="image-file-input"
            />
            <label htmlFor="image-file-input">
              <Button variant="outlined" component="span">
                เลือกรูปภาพ
              </Button>
            </label>

            {selectedFile &&
              <div>
                <Typography variant="subtitle1" mt={1}>
                  Selected Image: {selectedFile.name}
                </Typography>
              </div>}
            {error &&
              <Typography variant="body2" color="error" mt={2}>
                {error}
              </Typography>}
          </Box>

          <hr />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            ยืนยัน
          </Button>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Add_doc;
