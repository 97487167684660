import React from "react";

import "./css/Firstpage.css";

const Firstpage = () => {
  setTimeout(() => {
    window.open("/Archive/login", "_self");
  }, 2000);

  return (
    <React.Fragment>
      <div class="loadingio-spinner-bean-eater-teu3rb1sc3p">
        <div class="ldio-7d6v38vk6xc">
          <div>
            <div />
            <div />
            <div />
          </div>
          <div>
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Firstpage;
