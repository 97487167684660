import React, { useState } from "react";
import axios from "axios";

import {
  Card,
  CardContent,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button
} from "@mui/material";

import AccountCircle from "@mui/icons-material/AccountCircle";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import "./css/Login.css";

import Swal from "sweetalert2";

const Change_pass = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3001/api/";
  } else {
    xURL = "https://finance.rihes.cmu.ac.th/api/";
  }

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleClickShowPassword2 = () => setShowPassword2(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleMouseDownPassword2 = event => {
    event.preventDefault();
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!data.get("username")) {
      Swal.fire({
        title: "บันทึกไม่ได้!",
        text: "ยังไม่ได้คีย์ Username",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else if (!data.get("password1")) {
      Swal.fire({
        title: "บันทึกไม่ได้!",
        text: "ยังไม่ได้คีย์ Old Password",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else if (!data.get("password2")) {
      Swal.fire({
        title: "บันทึกไม่ได้!",
        text: "ยังไม่ได้คีย์ New Password",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else if (data.get("password1") == data.get("password2")) {
      Swal.fire({
        title: "บันทึกไม่ได้!",
        text: "Password ใหม่ ซ้ำกับ Password เดิม",
        icon: "warning",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "my-swal"
        }
      });
    } else {
      // console.log(WorkNameSelect);

      axios
        .post(xURL + "getuser", {
          username: data.get("username")
        })
        .then(result => {
          if (!result.data[0]) {
            Swal.fire({
              title: "บันทึกไม่ได้!",
              text: "Username นี้ ไม่มีในระบบ",
              icon: "warning",
              showConfirmButton: false,
              timer: 2000,
              customClass: {
                container: "my-swal"
              }
            });
          } else {
            axios
              .post(xURL + "getPass", {
                username: data.get("username"),
                password: data.get("password1")
              })
              .then(result2 => {
                if (result2.data == "Password ไม่ถูกต้อง") {
                  Swal.fire({
                    title: "บันทึกไม่ได้!",
                    text: "Password เดิม ไม่ถูกต้อง",
                    icon: "warning",
                    showConfirmButton: false,
                    timer: 2000,
                    customClass: {
                      container: "my-swal"
                    }
                  });
                } else {
                  getChangeP(data.get("username"), data.get("password2"));
                }
              })
              .catch(function(error) {
                // handle error
                console.log("problem here", error);
              });
          }
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  };

  const getChangeP = (user, npass) => {
    // console.log(user, opass, npass);

    axios
      .post(xURL + "changePass", {
        username: user,
        npass: npass
      })
      .then(result => {
        if (result.data) {
          Swal.fire({
            title: "แก้ไขสำเร็จ!",
            text: "^_^",
            icon: "success",
            showConfirmButton: false,
            timer: 2000
          }).then(result => {
            window.open("/Archive/login", "_self");
          });
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  return (
    <React.Fragment>
      <div className="register-body" style={{ width: "100%", height: "100vh" }}>
        <Card
          sx={{ minWidth: 450 }}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "20%",
            height: "310px"
          }}
        >
          <CardContent>
            <p
              style={{
                fontSize: "30px",
                marginTop: "8px",
                textAlign: "center"
              }}
            >
              <span style={{ color: "red" }}>เปลี่ยน </span> Password
            </p>
            <hr style={{ marginTop: "-15px" }} />

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}
              >
                <InputLabel>Username</InputLabel>
                <OutlinedInput
                  name="username"
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  }
                  label="Username"
                  autoFocus
                />
              </FormControl>

              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 1 }}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Old Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  name="password1"
                  type={showPassword ? "text" : "password"}
                  startAdornment={
                    <InputAdornment position="start">
                      <VpnKeyIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Old Password"
                />
              </FormControl>

              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 1 }}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  New Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  name="password2"
                  type={showPassword2 ? "text" : "password"}
                  startAdornment={
                    <InputAdornment position="start">
                      <VpnKeyIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword2}
                        edge="end"
                      >
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="New Password"
                />
              </FormControl>

              <Button
                fullWidth
                variant="contained"
                style={{
                  fontFamily: "kanit"
                }}
                type="submit"
                sx={{ mt: 1 }}
              >
                ยืนยัน
              </Button>
            </Box>
          </CardContent>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Change_pass;
